import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import Img from "gatsby-image"
import sliderblocks from "./sliders.module.css"

export const swiperparams = {
    slidesPerView: 3,
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
  }

const SliderThemes = ({ data }) => (
  <div className = {sliderblocks.rowdiv}>
<h3>Staycation Ideas</h3>
    <Swiper {...swiperparams}>
          {data.allMarkdownRemark.edges.map(({ node }) => (
          <div className={sliderblocks.sliderblock}>
              <Link to={node.fields.slug}><Img fluid={node.keyimage.childImageSharp.fluid}
        />
        <div className={sliderblocks.floatingtext} ><span className = {sliderblocks.floatinglink}>{node.frontmatter.title}</span></div></Link>
          </div>
        ))}
        </Swiper>
        </div>
)

export default function MySliderThemes () {
    return (

  <StaticQuery
    query={graphql`
    {
        allMarkdownRemark(filter: {frontmatter: {type: {eq: "theme"}}}) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
              }
              keyimage {
                childImageSharp {
                        fluid(maxWidth: 330, maxHeight:400) {
                          ...GatsbyImageSharpFluid
                        }
                }
              }
            }
          }
        }
      }
    `} 
    render={data => <SliderThemes data={data} />}
  />
)
}

