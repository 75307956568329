import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import 'swiper/css/swiper.css';
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import PageIntro from "../../components/pageintro"
import SliderThemes from "../../components/slider-themes"


const ThemesPage = ({data}) => (
  <Layout>
    <SEO title="Types of Holiday in the UK" description="You can get up to allsorts here..." />
    <h1>Find out what types of staycation the UK offers</h1>
    <div style={{ marginBottom: `1.45rem` }}>
    <PageIntro>
    <h3>No matter what takes your fancy</h3>
    <p>Try something new, or stick to what you like.</p>
    <p>Browse these {data.allMarkdownRemark.totalCount} destinations below</p>
    </PageIntro>
    <ul>
    {data.allMarkdownRemark.edges.map(({ node }) => (
          <li>
              <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
          </li>
        ))}
    </ul>
    </div>
    <SliderThemes/>
  </Layout>
)

export default ThemesPage

export const query = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {type: {eq: "theme"}}}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`